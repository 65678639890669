// import '@Inszu-Inc/axiforma'
// import '@Inszu-Inc/axiforma/500.css'
import '@fontsource/inter'
import '@fontsource/inter/variable-full.css'
import './src/styles/global.css'

import { ApolloProvider } from '@apollo/client'
import { config } from '@fortawesome/fontawesome-svg-core'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import React, { useEffect, useRef, useState } from 'react'

import { apolloClient } from './src/apollo-client'
import { fetchAccessToken } from './src/backend/refresh-token'
import { setAccessToken } from './src/providers/access-token'

export function useRefreshToken() {
  const isMounted = useRef(false)
  const [isRefreshing, setIsRefreshing] = useState(true)
  useEffect(() => {
    if (isMounted.current) return
    fetchAccessToken()
      .then(res => res.json())
      .then(data => setAccessToken(data.accessToken))
      .finally(() => setIsRefreshing(false))
    isMounted.current = true
  }, [])
  return isRefreshing
}

export const onClientEntry = () => {
  /* Prevents fontawesome auto css insertion */
  config.autoAddCss = false
}

gsap.registerPlugin(ScrollToPlugin)

function RootElement({ children }) {
  const isRefreshing = useRefreshToken()
  if (isRefreshing) return <div>Loading</div>
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}
