import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { navigate } from 'gatsby'
import some from 'lodash/some'

import { fetchAccessToken } from './backend/refresh-token'
import { graphqlEndpoint } from './common/config'
import { getAccessToken, hasExpired, setAccessToken } from './providers/access-token'

const httpLink = new HttpLink({ uri: graphqlEndpoint, credentials: 'include' })

const authLink = setContext(() => {
  const accessToken = getAccessToken()
  if (!accessToken) return {}
  return {
    headers: { authorization: `bearer ${accessToken}` },
  }
})

const logoutLink = onError(({ networkError, response }) => {
  if (response?.errors) {
    const hasAuthError = some(response.errors, error =>
      ['UNAUTHENTICATED', 'FORBIDDEN'].includes(error.extensions?.code as string),
    )
    if (hasAuthError) {
      setAccessToken()
      navigate('/')
      response.errors = undefined
    }
  }
  if (!networkError) return
  if ('statusCode' in networkError && networkError.statusCode === 401) {
    navigate('/')
    if (response) {
      response.errors = undefined
    }
  }
})

const tokenLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    const token = getAccessToken()
    if (!token) return true
    try {
      return !hasExpired()
    } catch (error) {
      return false
    }
  },
  fetchAccessToken,
  handleFetch: accessToken => setAccessToken(accessToken),
  handleError: error => console.error(error),
})

const link = from([tokenLink, authLink, logoutLink, httpLink])

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})
