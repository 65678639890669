module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FormBuilder","short_name":"FormBuilder","start_url":"/","background_color":"#fafafa","theme_color":"#0ea5e9","display":"standalone","icon":"./logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"30c0a3817eaa4b8610f04aa067252e95"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
