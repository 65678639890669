import jwtDecode, { JwtPayload } from 'jwt-decode'

let accessToken: string | undefined
export const setAccessToken = (token?: string) => (accessToken = token)
export const getAccessToken = () => accessToken

export const hasExpired = () => {
  if (!accessToken) return true
  const { exp = Infinity } = jwtDecode<JwtPayload>(accessToken)
  return Date.now() > exp * 1000
}
