import { setAccessToken } from 'src/providers/access-token'

import { apiEndpoint } from '../common/config'

interface TokenResponse {
  accessToken: string
}

export const fetchAccessToken = () =>
  fetch(`${apiEndpoint}/auth/refresh_token`, {
    method: 'POST',
    credentials: 'include',
  })

export const refreshToken = () => {
  let status = 'pending'
  const suspender = fetchAccessToken()
    .then(
      res => {
        status = 'success'
        return res.json()
      },
      () => (status = 'error'),
    )
    .then((data: TokenResponse) => {
      setAccessToken(data.accessToken)
    })
  return {
    read() {
      if (status === 'pending') {
        throw suspender
      } else if (status === 'error') {
        return
      } else if (status === 'success') {
        return
      }
    },
  }
}
