export const inspectStateCharts = process.env.GATSBY_INSPECT_STATE_CHARTS === 'true'
export const dateFormat = 'yyyy/MM/dd'

export const encryptionKeyBits = 128
export const ivLengthBytes = 12

export const apiEndpoint = process.env.GATSBY_API_ENDPOINT
export const graphqlEndpoint = process.env.GATSBY_GRAPHQL_ENDPOINT

export const debugNamespaces = (process.env.GATSBY_DEBUG || '').split(',') || []
